import React, { Component } from "react";
import Avatar from "../views/pages/AvatarComponent";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Spinner } from "reactstrap";
import { IKImage } from "imagekitio-react";

class TaggingDropdown extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.hideDropdown();
    }
  }

  userClickHandler = (user) => {
    const taggedUser = { id: user.id, username: user.username };
    this.props.addTaggedUser(taggedUser);
    this.props.hideDropdown();
  };

  render() {
    const { users } = this.props;
    return (
      <div ref={this.wrapperRef}>
        {/* {users?.length > 0 && ( */}
        <PerfectScrollbar className="tagging-dropdown">
          <ul className="pl-0">
            {this.props.isLoading ? (
              <div className="d-flex justify-content-center p-1">
                <Spinner />
              </div>
            ) : (
              users?.map((user) => (
                <li
                  key={user.id}
                  className="d-flex"
                  onClick={() => this.userClickHandler(user)}
                >
                  {user.image ? (
                    // <img src={S3_BASE_URL + user.image} alt="user avatar" />
                    process.env.REACT_APP_USE_IMAGEKIT === "0" ? (
                      <img
                        src={`${process.env.REACT_APP_S3_BASE_URL}${user.image}`}
                        height="60"
                        width="60"
                      />
                    ) : (
                      <IKImage
                        path={user.image}
                        transformation={[
                          {
                            width: 60,
                            height: 60,
                          },
                        ]}
                        height="60"
                        width="60"
                      />
                    )
                  ) : (
                    <Avatar
                      content={
                        user.nickName?.charAt(0) || user.username?.charAt(0)
                      }
                      className="marg-0"
                      size="sm"
                    />
                  )}
                  <div>
                    <p className="mb-0">{user.nickName || user.username}</p>
                    <small>@{user.username}</small>
                  </div>
                </li>
              ))
            )}
          </ul>
        </PerfectScrollbar>
        {/* )} */}
      </div>
    );
  }
}

export default TaggingDropdown;
