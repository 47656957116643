import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge,
  Modal,
  ModalBody,
} from "reactstrap";
import { Settings, Power, User } from "react-feather";
import { Link } from "react-router-dom";
import Avatar from "../../../views/pages/AvatarComponent";
import { connect } from "react-redux";
import IconClose from "../../../assets/img/icon-cross.svg";
import logo from "../../../assets/img/logo/logo.png";
import { IKImage } from "imagekitio-react";
import { getUser, logout } from "../../../services/authService";
import NotificationsDropdown from "./notificationsDropdown";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";

class NavbarUser extends React.PureComponent {
  state = {
    dropdown: false,
    isLogoutAlertModalOpen: false,
    isLoggingOut: false,
    isConfirmationModalOpen: false,
  };

  logoutHandler = async () => {
    try {
      this.setState({ isLoggingOut: true, isConfirmationModalOpen: false });
      if (this.props.isUserLive) {
        const isSameSession = await this.isUserLiveFromThisSession();

        if (isSameSession) {
          this.setState({ isLogoutAlertModalOpen: true, isLoggingOut: false });
          return;
        }
      }

      await logout();
      window.localStorage.clear();
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_BASE_URL;
      }, 300);
    } catch (err) {
      console.log(err);
      this.setState({ isLoggingOut: false });
      toast.error("Something went wrong, please try again later");
    }
  };

  isUserLiveFromThisSession = async () => {
    try {
      const response = await getUser();
      if (response.data?.user) {
        const user = response.data.user;
        const token = window.localStorage.getItem("token");
        /*
          Don't allow to log out if auth_token is not set
          We can't determine if live is from the same session if auth_token is not set
          auth_toke is set when the live stream become active
        */
        return !user.auth_token || user.auth_token === token;
      }
    } catch (err) {
      console.log(err);
    }
  };

  closeLogoutAlertModal = () => {
    this.setState({ isLogoutAlertModalOpen: false });
  };

  closeConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: false });
  };

  render() {
    return (
      <>
        <ul className="nav navbar-nav navbar-nav-user float-right customNav justify-content-end">
          <li className="d-flex nav-stats">
            {this.props.user?.isApprovedModel === 1 ? (
              <div>
                <div className="header-wallet-box">
                  <Link to="/settings/#/earnings">
                    <Badge pill className="badge-up" color="warning">
                      Earning:{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(this.props.user?.balanceAmount)}
                    </Badge>
                  </Link>
                </div>
              </div>
            ) : null}
            <div>
              <div className="header-wallet-box">
                <Link to="/settings/#/wallet">
                  <Badge pill className="badge-up" color="primary">
                    Wallet:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(this.props.user?.walletAmount)}
                  </Badge>
                </Link>
              </div>
            </div>
          </li>
          <NotificationsDropdown />
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
              <div className="user-nav d-sm-flex d-none">
                <span
                  className="user-name text-bold-600"
                  style={{ textTransform: "capitalize" }}
                >{`${
                  this.props.user?.nickName || this.props.user?.username
                }`}</span>
                <span className="user-status">
                  {this.props.user?.isApprovedModel
                    ? this.props.user?.applied_as ?? "Model"
                    : "Fan"}
                </span>
              </div>

              <span data-tour="user">
                {this.props.userImg ? (
                  process.env.REACT_APP_USE_IMAGEKIT === "0" ? (
                    <img
                      src={`${process.env.REACT_APP_S3_BASE_URL}${
                        this.props.userImg.split(".com/")[1]
                      }`}
                      width="40"
                      height="40"
                      className="round"
                    />
                  ) : (
                    <IKImage
                      path={this.props.userImg.split(".com/")[1]}
                      transformation={[
                        {
                          width: 60,
                          height: 60,
                        },
                      ]}
                      width="40"
                      height="40"
                      className="round"
                    />
                  )
                ) : (
                  <Avatar
                    content={
                      this.props.user?.nickName?.charAt(0) ??
                      this.props.user?.username?.charAt(0)
                    }
                  />
                )}
              </span>
            </DropdownToggle>
            <DropdownMenu right className="userCustomDropdown">
              <Link to="/profile">
                <DropdownItem style={{ width: "100%", color: "#800080" }}>
                  <User
                    style={{
                      color:
                        this.props.theme === "Light" ? "#800080" : "#d3af37",
                    }}
                    size={14}
                    className="mr-50"
                  />
                  <span className="align-middle">Profile</span>
                </DropdownItem>
              </Link>

              <DropdownItem divider />
              <Link to="/settings">
                <DropdownItem style={{ width: "100%", color: "#800080" }}>
                  <Settings
                    style={{
                      color:
                        this.props.theme === "Light" ? "#800080" : "#d3af37",
                    }}
                    size={14}
                    className="mr-50"
                  />
                  <span className="align-middle">Settings</span>
                </DropdownItem>
              </Link>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => this.setState({ isConfirmationModalOpen: true })}
                style={{ width: "100%", color: "#800080" }}
              >
                <Power
                  style={{
                    color: this.props.theme === "Light" ? "#800080" : "#d3af37",
                  }}
                  size={14}
                  className="mr-50"
                />
                <span className="align-middle">Log Out</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ul>

        <Modal
          isOpen={this.state.isLogoutAlertModalOpen}
          centered
          toggle={this.closeLogoutAlertModal}
          className="model-suggestion-modal"
        >
          <div className="modal-close-btn" onClick={this.closeLogoutAlertModal}>
            <img src={IconClose} alt="close-icon" />
          </div>
          <ModalBody>
            <h3 className="text-center">
              Sorry, unable to log out since you have an active live stream.
              Click{" "}
              <Link
                to="/streaming"
                style={{ color: "#d3af37", textDecoration: "underline" }}
                onClick={this.closeLogoutAlertModal}
              >
                here
              </Link>{" "}
              to access the live stream to end it before logging out.
            </h3>
          </ModalBody>
        </Modal>

        <ConfirmationModal
          message={"Are you sure you want to log out?"}
          buttonText={"Log out"}
          isModalOpen={this.state.isConfirmationModalOpen}
          closeModal={this.closeConfirmationModal}
          action={this.logoutHandler}
        />

        <Modal isOpen={this.state.isLoggingOut} centered>
          <img className="fallback-logo logo-anim" src={logo} alt="logo" />
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.app.user,
    theme: state.customizer.theme,
    isUserLive: state.live.isUserLive,
  };
};

export default connect(mapStateToProps)(NavbarUser);
